export const Courier = [
    'CJ대한통운',
    '로젠택배',
    '옐로우캡',
    '우체국택배',
    '한진택배',
    '롯데택배',
    '드림택배',
    'KGB택배',
    '대신택배',
    '일양로지스',
    '경동택배',
    '천일택배',
    '합동택배',
    'CVSnet편의점택배',
    '우편발송GTX',
    '로지스건영택배',
    '드림택배',
    'EMS',
    'DHL',
    'FedEx',
    'UPS',
    'USPS',
    'TNT',
    'Express한의사랑택배',
    'GSMNtoN(인로스)',
    '에어보이익스프레스',
    'DHL Global Mail',
    'i-Paracel',
    '기타발송',
];
