import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { acitiveStepChange } from 'store/part';

const TosWrap = styled.article`
    width: 100%;

    h2 {
        margin-bottom: 20px;
        text-align: center;
    }
    .agreeBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }
    .agreeBox > .inputBox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }
    .agreeBox > .inputBox > label {
        margin: 0 10px;
        display: inline-block;
    }
`;

const TosBox = styled.article`
    width: 100%;
    height: 500px;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #ccc;
`;

const Tos = ({ stepIndex, steps }) => {
    const dispatch = useDispatch();
    const { activeStep } = useSelector(state => state.part);
    const [inputChecked, setInputChecked] = useState(false);
    const handleChange = e => {
        setInputChecked(e.target.checked);
    };
    const handleNext = () => {
        dispatch(acitiveStepChange(activeStep + 1));
    };
    return (
        <TosWrap>
            <h2>이용약관</h2>
            <TosBox>
                웹사이트 이용 약관 알로하! 하와이에서 가장 크고 가장 오래된 항공사이며 미국 대륙의 주요 방문자 시장에서
                가장 큰 정기 항공 서비스 제공업체인 Hawaiian Airlines, Inc.("회사" 또는 "당사")의 웹사이트에 오신 것을
                환영합니다. 참조로서 명시적으로 통합된 추가 약관과 함께 다음 이용 약관("이용 약관"으로 통칭)은 본
                사이트에서 또는 본 사이트를 통해 제공된 콘텐츠, 기능 및 서비스는 물론 본 사이트의 이용 및 사용에
                적용됩니다. 본 이용 약관의 승인. 본 웹사이트 및 해당 응용 프로그램("사이트"로 통칭)을 이용함으로써
                사용자("귀하")는 제한이나 자격 요건 없이 모든 해당 이용 약관 및 본 이용 약관의 통지를 준수할 것에
                동의하게 됩니다. 사이트 사용을 시작하기 전에 본 이용 약관을 자세히 확인하시기 바랍니다. 사이트를
                사용함으로써 귀하는 귀하가 회사와 계약을 체결할 수 있는 법적 연령임을 당사에 진술하고 보증합니다. 귀하가
                계약을 체결할 수 없는 경우 사이트를 이용 또는 사용해서는 안 됩니다. 당사는 모든 사용자에 대해 언제든지
                사이트 이용을 거부할 권리가 있습니다. 변경. 당사는 통지 또는 책임 없이 사이트에 포함된 제품이나
                프로그램은 물론 본 이용 약관을 수정할 권리가 있습니다. 모든 변경은 당사가 변경을 게시하고 그 이후 모든
                사이트 이용 및 사용에 적용하는 즉시 유효합니다. 개정된 이용 약관의 게시에 따라 사이트를 계속 사용하는
                것은 귀하가 변경을 수락하고 동의함을 의미합니다. 변경이 귀하에 대해 구속력을 가지므로 귀하는 이러한
                변경을 알도록 사이트를 이용할 때마다 확인해야 합니다. 콘텐츠 소유권 및 정확성. 계정 정보 등 사이트의
                모든 콘텐츠는 회사가 소유하거나 제어하며 미국 내 또는 국제적으로 저작권법에 의해 보호됩니다. 사이트에
                제공된 정보는 내부 및 외부 소스에서 당사가 컴파일합니다. 그러나 해당 정보의 완전성 또는 정확성을
                진술하거나 보증하지 않습니다. 귀하는 제공된 정보가 불완전하거나, 오류가 포함되거나 오래된 내용으로 될 수
                있음을 유의해야 합니다. 따라서 사이트에서 얻은 정보로 작업하기 전에 해당 정보를 확인해야 합니다. 회사는
                사이트 이용하거나 이용할 수 없음으로 인해 발생하거나 사이트에서 제공된 정보를 신뢰하여 발생하는 손해
                또는 상해에 대해 책임을 지지 않습니다. 사이트에는 다른 사용자 및 제3자 라이선스 허여자, 신디케이터, 통합
                공급업체 및/또는 보고 서비스에서 제공된 자료를 포함한 제3자가 제공한 콘텐츠가 포함될 수 있습니다.
                회사에서 제공한 콘텐츠 외에 이러한 자료에 명시된 모든 안내 및/또는 의견은 전적으로 해당 자료를 제공하는
                개인 또는 업체의 의견 및 책임입니다. 이러한 자료는 필수적으로 회사의 의견을 반영하지 않습니다. 당사는
                제3자가 제공한 자료의 내용이나 정확성에 대해 귀하 또는 제3자에게 책임을 지지 않습니다. 회사는 상업성,
                비침해성 및 특정 목적에의 적합성에 대한 보증을 포함(이에 국한되지 않음)하여 명시적, 묵시적, 법적 또는
                기타 보증 등 모든 보증을 여기서 부인합니다. 위 내용은 관련 법률에 따라 배제되거나 제한될 수 없는 보증에
                영향을 주지 않습니다. 회사 및/또는 그 공급자는 어떤 경우에도 사이트 사용, 지연 또는 사용할 수 없음, 또는
                사이트를 통해 얻는 모든 정보, 소프트웨어, 제품 및 서비스로 인해 또는 그와 관련하여 발생하거나 사이트
                사용으로 인해 발생하는 직접, 간접, 징벌적, 부수적, 특별 또는 결과적 손해에 대해 책임을 지지 않으며 계약,
                불법 행위, 엄격한 책임 등을 기반으로 하거나 회사 및/또는 그 공급자가 그러한 손해의 가능성을 사전에 알고
                있던 경우에도 마찬가지입니다. 일부 주/관할 구역에서는 결과적 또는 부수적 손해에 대한 책임의 배제 또는
                제한이 허용되지 않으므로 위 제한이 귀하에게 적용되지 않을 수도 있습니다. 침해. 귀하의 작업이 사이트에서
                또는 사이트를 통해 귀하의 권리를 침해하는 방식으로 복사 및 게시된다고 판단하는 경우 (i) 침해되었다고
                청구된 작업의 식별 정보 또는 여러 작업에 단일 통지가 적용되는 경우 해당 작업의 대표 목록, (ii)
                침해되었다고 청구된 자료의 식별 정보 및 당사가 사이트에서 해당 자료를 찾을 수 있도록 합리적으로 충분한
                정보, (iii) 귀하의 연락처 정보 - 주소, 전화 번호, 이메일 주소(해당하는 경우), (iv) 신의에 따라 성실하게
                문제가 된 사용이 소유자, 대리인 또는 법률에서 승인을 받지 못함을 나타내는 소견서, (v) 통지의 정보가
                정확하고 귀하가 소유자이거나 소유자 대신 행동할 권리가 있음을 나타내는 소견서 및 (vi) 귀하의 서명 정보와
                함께 침해에 대한 청구 통지를 당사로 보내십시오. 당사는 귀하의 청구를 조사한 후 적절한 때에 통지에
                응답합니다. 사용자 정보. 사이트 또는 사이트에서 제공하는 일부 리소스에 액세스하려면 특정 등록 세부 정보
                또는 기타 정보를 제공해야 합니다. 본 사이트의 사용 조건으로 사이트에 제공하는 모든 정보는 정확하며, 최신
                상태의 완전한 정보여야 합니다. 귀하는 사이트의 대화형 기능 사용(해당 기능 사용을 포함하며 이에 제한되지
                않음)을 통해 사이트 또는 기타 장소에 등록하기 위해 귀하가 제공하는 모든 정보는 회사의 개인정보정책이
                적용됨에 동의하며, 개인정보정책과 일관된 귀하의 정보와 관련하여 당사가 취하는 모든 조치에 동의합니다.
                사이트에는 메시지 보드, 채팅방, 개인 웹 페이지 또는 프로필, 포럼, 게시판 및 사용자가 사이트에서 또는
                사이트를 통해 콘텐츠 또는 자료("사용자 기여"로 통칭)를 게시, 제출, 공개, 전시 또는 다른 사용자나 기타
                개인에게 전달할 수 있는 기타 공개 대화형 기능이 포함될 수 있습니다. 사이트에 게시하는 사용자 기여는
                비밀이 아니며 독점이 아닌 것으로 간주됩니다. 사이트에 사용자 기여를 제공함으로써 귀하는 당사, 당사의
                계열사, 서비스 공급자 및 각 해당 라이선스 실시권자, 승계인, 양수인에게 이러한 자료를 어떠한 목적으로든
                사용, 복제, 수정, 실행, 전시, 배포 및 기타 제3자에게 공개할 권리를 부여합니다. 사용자 기여는 비방, 모욕,
                괴롭힘, 스토킹, 위협 또는 기타 방식으로 다른 사람의 법적 권리(예: 사생활 보호권, 초상권)를 침해하는
                행위, 비방, 침해, 음란, 퇴폐적이거나 불법적인 자료 또는 정보를 공개, 게시, 배포 또는 유포하는 행위,
                귀하가 그에 대한 권리를 소유 또는 제어하거나 필요한 동의를 모두 얻은 경우를 제외하고 지적 재산권(또는
                사생활 보호권이나 공개 권리)에 의해 보호되는 소프트웨어 또는 기타 자료를 포함하는 파일을 업로드하는
                행위, 다른 사람의 컴퓨터 작동에 손상을 줄 수 있는 바이러스를 포함하는 파일, 손상된 파일 또는 기타 유사한
                소프트웨어나 프로그램을 업로드하는 행위, 업로드하는 파일에 있는 작성자 권한, 법적 고지 사항, 소유를
                나타내는 명칭 또는 레이블을 삭제하는 행위, 업로드되는 파일에 포함된 소프트웨어 또는 기타 자료의 원본이나
                출처를 위조하는 행위, 제품 또는 서비스를 광고하거나 상업적 목적으로 판매를 제안하거나 설문 조사, 공모전,
                행운의 편지에 가담하는 행위 또는 사이트를 통해 합법적으로 배포할 수 없음을 귀하가 알고 있거나 합리적으로
                알고 있어야 하는 모든 방법으로 파일을 다운로드하는 행위에 사용할 수 없습니다. 귀하는 귀하가 제출 또는
                기여한 사용자 기여의 책임이 귀하에게 있으며 적법성, 신뢰성, 적확성 및 적절성을 포함하여 해당 콘텐츠가
                회사가 아닌 전적으로 귀하의 책임임을 이해하고 인정합니다. 귀하는 당사가 사용자 기여로 발생한 비방, 공격,
                침해 또는 불법 자료나 불법 행위에 대해 책임을 지지 않으며, 책임 없이 사이트에서 해당 자료 또는 기타
                자료를 삭제할 권리(의무 사항 아님)를 보유함을 특별히 인정합니다. 당사는 사이트에 게시되기 전에 모든
                사용자 기여 자료를 검토한다고 약속하지 않으며 게시된 후 유해한 자료를 즉시 삭제한다고 보증하지 않습니다.
                귀하는 또한 사용자 기여와 관련한 비방, 공격, 침해 또는 불법 자료나 불법 행위로 인해 발생한 모든 손해 및
                비용에 대해 당사를 면책함에 동의합니다. 당사는 또한 사이트에 또는 사이트를 통해 자료를 게시한 사람의
                신원 정보나 기타 정보를 공개하도록 요청하거나 지시하는 사법기관 또는 법원 명령에 전적으로 협조할 권리를
                보유합니다. 사이트 소유권 및 마크. 사이트 및 사이트의 전체 콘텐츠 및 기능(모든 정보, 소프트웨어, 텍스트,
                전시, 이미지, 비디오와 오디오 및 디자인, 선택 및 배열 일부)은 회사, 해당 라이선스 허여자 또는 기타
                이러한 자료 공급자가 소유하며 미국 및 국제 저작권, 상표, 특허, 영업 비밀 및 기타 지적재산권 또는
                소유권법에 의해 보호됩니다. 사이트 또는 사이트의 모든 콘텐츠에 대한 권리, 소유권 또는 이권은 귀하에게
                양도되지 않으며 명시적으로 부여되지 않은 모든 권리는 회사가 보유합니다. 본 이용 약관에서 명시적으로
                허용하지 않는 사이트의 사용은 본 이용 약관의 위반이며 저작권, 상표 및 기타 법률의 위반일 수 있습니다.
                회사의 이름, 로고, 마크 및 모든 관련 이름, 로고, 제품 및 서비스 이름, 디자인 및 슬로건은 회사, 회사의
                계열사 또는 라이선스 허여자의 상표입니다. 회사의 사전 서면 승인 없이 해당 마크를 사용해서는 안 됩니다.
                사이트의 모든 다른 이름, 로고, 제품 및 서비스 이름, 디자인 및 슬로건은 해당 소유자의 상표입니다. 사용
                제한. 사이트는 개인적, 비상업적 용도로만 사용됩니다. 귀하는 당사의 사전 서면 승인 없이 사이트에서 얻은
                모든 정보, 소프트웨어, 제품 또는 서비스를 수정, 복사, 변경, 배포, 전송, 전시, 실행, 복제, 공개, 라이선스
                허여, 파생물 제작, 양도, 또는 판매할 수 없음에 동의합니다. 귀하는 당사의 사전 서면 승인 없이 어떠한
                목적으로도 사이트 또는 사이트에 포함된 콘텐츠를 모니터링 또는 복사하기 위해 자동 장치, 수동 프로세스
                또는 스크랩을 사용하지 않음에 동의합니다. 귀하는 사이트의 정상적인 작동 또는 사이트에서 수행된 모든
                활동을 방해하거나 방해하려고 시도하기 위해 장치, 소프트웨어 또는 루틴을 사용하지 않음에 동의합니다.
                귀하는 회사의 사전 서면 승인 없이 어떠한 승인되지 않은 목적으로도 사이트 또는 사이트에 포함된 콘텐츠를
                모니터링 또는 복사하기 위해 로봇, 스파이더, 기타 자동 장치 또는 수동 프로세스를 사용하지 않음에
                동의합니다. 당사는 사이트의 무단 프레이밍 또는 로봇, 스파이더 또는 기타 자동 장치의 무단 사용을
                포함한(이에 제한되지 않음) 사이트의 불법 및/또는 무단 사용을 조사할 권리를 보유하며 민사, 형사 및 배상
                명령을 포함한(이에 제한되지 않음) 적절한 법적 조치를 취할 권리가 있습니다. 예약 기능. 사이트의 예약
                기능은 여행 관련 상품 및 서비스의 가용성 확인 및 합법적인 예약 수행 또는 여행 제공업체와의 비즈니스
                거래를 허용하기 위한 목적으로만 제공되며 기타 다른 어떠한 목적으로도 사용되지 않습니다. 제한 없이 귀하는
                투기, 허위 또는 사기 목적의 구매나 예약 또는 수요를 예측한 예약을 수행할 수 없습니다. 개인이 하나 이상의
                목적지 또는 동일한 날짜에 대해 여러 예약을 확정했다고 당사에서 확인하는 경우 당사는 탑승객 또는 예약을
                한 개인에 통지하지 않고 여러 예약과 관련된 모든 확정 부분을 취소할 권리를 보유합니다. 귀하는 사이트의
                예약 기능이 합법적인 예약이나 구매에 사용된다는 것에 동의합니다. 귀하는 기한 내 모든 금액의 지불 또는
                상환을 포함하여(이에 제한되지 않음) 거래하기로 한 공급자가 부과한 구매의 이용 약관을 준수하며 요금, 제품
                또는 서비스의 가용성과 관련된 모든 규칙을 준수할 것에 동의합니다. 귀하는 사이트 사용으로 발생하는 비용,
                요금, 의무, 세금 및 평가에 대해 완전하게 책임을 집니다. 사이트에 연결 및 사이트에서 연결. 공정하고
                합법적이며 당사의 평판이나 이용에 손해를 끼치지 않는 방식으로 연결하는 경우 사이트의 홈 페이지에 연결할
                수 있습니다. 그러나 당사의 명시적인 서면 동의 없이 회사 부분에 대해 연계, 승인 또는 보증의 형태를
                제안하는 방식으로 연결해서는 안 됩니다. 사이트에 다른 사이트 및 제3자가 제공한 리소스에 대한 링크가
                포함되어 있는 경우 이 링크는 편의상의 목적으로만 제공됩니다. 해당 링크에는 배너 광고와 스폰서 링크 등
                광고에 포함된 링크가 포함됩니다. 당사는 이러한 사이트 및 리소스의 콘텐츠에 대한 제어 권한이 없으며 해당
                콘텐츠 또는 콘텐츠 사용으로 인해 발생할 수 있는 손실이나 손해에 대해 책임을 지지 않습니다. 사이트에
                연결된 제3자 웹사이트에 액세스할 경우 책임은 전적으로 귀하에게 있으며 귀하는 해당 웹사이트의 사용 약관을
                준수해야 합니다. 무단 사용. 모든 계정 정보는 회사가 소유합니다. 보안 절차의 일부로 사용자 이름, 암호
                또는 기타 정보를 선택(또는 제공됨)한 경우 해당 정보를 비밀로 취급해야 하며 다른 개인이나 기관에
                공개해서는 안 됩니다. 또한 귀하는 계정이 귀하에게만 국한됨을 인정하고 다른 개인이 귀하의 사용자 이름,
                암호 또는 기타 보안 정보를 사용하여 사이트 또는 사이트 일부에 대해 액세스하지 않도록 할 것에 동의합니다.
                귀하가 암호 또는 개인 번호가 할당된 HawaiianMiles 회원("회원") 또는 (ii) 회원의 승인으로 활동하는 회원의
                가족 구성원, 직원 또는 승인된 개인이 아닌 경우 귀하는 로그인 중 HawaiianMiles 회원의 암호 또는 개인
                번호를 활용하는 것을 포함하여(이에 제한되지 않음) 사이트를 유용하지 않을 것에 동의합니다. 계정 액세스는
                마일리지 관리 서비스, 마일리지 추적 서비스 또는 마일리지 집계 서비스를 포함한(이에 제한되지 않음) 제3자
                온라인 서비스에 제공되지 않습니다. 공영 또는 공유 컴퓨터에서 계정에 액세스할 때 다른 사람이 귀하의 암호
                또는 개인 정보를 보거나 기록할 수 없도록 특별히 주의해야 합니다. 당사는 언제든지 자체 판단에 따라 귀하
                또는 제3자가 본 이용 약관의 조항을 위반했다고 판단되는 경우를 포함하여 어떤 사유로든 또는 아무런 사유
                없이 귀하가 선택하거나 당사가 제공한 사용자 이름, 암호 또는 기타 식별자를 해제할 수 있는 권리가
                있습니다. 면책. 귀하는 사용자 기여를 포함한(이에 제한되지 않음) 본 이용 약관 또는 사이트 사용과 본 이용
                약관에 명시적으로 승인된 것 이외 사이트의 콘텐츠, 서비스 및 제품 사용 위반으로 발생하거나 이와 관련하여
                발생한 모든 청구, 책임, 손해, 판결, 상, 손실, 비용, 경비 또는 요금(합당한 변호사 수임료 포함)으로부터
                회사, 해당 계열사, 라이선스 허여자, 서비스 공급자 및 해당 담당자, 임원, 직원, 계약자, 대리인, 라이선스
                허여자, 승계인 및 양수인을 방어, 면책 및 보호할 것에 동의합니다. 운송 약관. 회사 항공기 또는 코드 셰어
                제휴사의 승객, 수화물 및 화물 운송에는 항공사의 운송 계약에 포함된 이용 약관 및 사이트에 지정된 이용
                약관(항공권 표지, 항공권 영수증 또는 게시된 스케줄에 인쇄됨)이 적용됩니다. 항공권을 구매하고 운송에
                동의함으로써 승객은 해당 약관을 준수할 것에 동의합니다. 사이트에 설명된 기타 서비스 및 제품에도 이용
                약관이 적용되며 자세한 내용은 회사 또는 해당 서비스 공급자(해당하는 경우)에서 얻을 수 있습니다. 준거법
                및 관할 법원. 본 계약에는 기타 관할 구역의 법이 적용되는 법 조항의 충돌에 관계없이 하와이 주법이
                적용됩니다. 귀하는 본 계약과 관련하여 또는 본 계약으로 인해 발생하는 소송은 하와이 주 호놀룰루 시에 있는
                연방 법원 또는 주 법원에 제기되며 다른 곳에는 제기되지 않는다는 데 동의합니다. 귀하는 해당 법원이 관할
                법원이 되는 데 동의하며 귀하가 사용할 수 있는 관할권 또는 관할 법원 방어권을 포기합니다. 권리 포기 및
                분리가능성. 본 이용 약관에 명시된 이용 약관에 대한 회사의 권리 포기는 이후 또는 계속 해당 이용 약관 또는
                다른 이용 약관의 권리를 포기하는 것으로 간주되지 않으며, 회사가 본 이용 약관에 따른 권리나 조항에 대한
                주장을 실패하는 경우 해당 권리나 조항의 권리 포기로 해석할 수 없습니다. 법원 또는 기타 관할 중재
                판정부에서 본 이용 약관의 조항이 어떤 이유로든 무효, 위법 또는 집행불능으로 판정될 경우 해당 조항은 제거
                또는 최소 범위로 제한되고, 본 이용 약관의 나머지 조항은 그대로 유효하게 됩니다. 지리적 제한 사항. 회사는
                미국 내 하와이 주를 기반으로 합니다. 당사는 사이트 또는 해당 콘텐츠가 미국 외의 지역에서 이용할 수
                있거나 적절한지를 보증하지 않습니다. 특정 관할 구역에서는 사이트 이용이 제한될 수 있습니다. 미국 외의
                지역에서 사이트를 이용하는 경우 귀하가 주도하여 사이트를 이용하며 현지 법률을 준수할 책임이 있습니다.
                예상 보고서. 사이트에는 회사 또는 모회사인 Hawaiian Holdings, Inc. 관련 예상 보고서가 포함될 수
                있습니다. 예상 보고서는 지정된 날짜부터 기술하며 이러한 날짜가 지정되지 않은 경우 보고서가 사이트에
                게시된 날짜부터 기술합니다. 실제 결과는 예상 보고서에 기술된 결과와 현저하게 다를 수 있습니다. 실제
                결과와 사이트의 예상 보고서에 기술된 결과가 현저하게 달라지게 될 수 있는 위험, 불확실성 및 기타 요인에
                대한 자세한 내용은 수정된 연간, 정기적 및 현재 보고서를 포함하여 모기업의 기록물(www.sec.gov/edgar.shtml
                {0})을 확인하십시오. 회사 및 모회사는 관련 법률에서 요구하지 않는 한 지정된 날짜 이후에 발생할 수 있는
                이벤트 또는 상황을 반영하기 위해 사이트에 포함되거나 사이트에서 참조된 예상 보고서를 업데이트, 수정,
                추가 또는 다른 방법으로 개정할 의무를 명시적으로 부인합니다.
            </TosBox>
            <article className="agreeBox">
                <article className="inputBox">
                    <input type="checkbox" id="agree" onChange={e => handleChange(e)} />
                    <label htmlFor="agree">동의하시겠습니까?</label>
                </article>
                {/* <Button disabled={stepIndex === 0}>Back</Button> */}
                <Button variant="contained" color="primary" disabled={inputChecked ? false : true} onClick={handleNext}>
                    {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </article>
        </TosWrap>
    );
};

export default Tos;
