import React from 'react';
import { FooterWrap, FooterContent } from 'styles/CommonStyles';
import naver from '../../styles/img/naver.png';
import kakao from '../../styles/img/kakao.png';
import { Link } from 'react-router-dom';

const FooterComponent = () => (
    <FooterWrap>
        <FooterContent>
            {/* <p>Company: Whosegoods Corp.</p>
            <p>Address: 5, Gasan digital 1-ro, Geumcheon-gu, Seoul, Republic of Korea</p>
            <p>CEO: Yonghyun Kim</p>
            <p>Business Registration No. 140-87-00556 | E-commerce Permit: 2017-Seoul-0142</p>
            <p>Zip Code: 08594 Phone:+82-2-2634-1121 Fax: +82-2-2633-1124</p>
            <p>CS Email: sazaxa.info@whosegoods.com</p>
            <p>COPYRIGHT © WHOSEGOODS. ALL RIGHTS RESERVED</p> */}
            <div className="bank">
                <h4>무통장입금 계좌정보</h4>
                <span>기업은행</span>
                <span>500-058476-04-017</span>
                <p>주식회사 에즈비에프</p>
            </div>
            <div className="footer" style={{ marginBottom: '0.5rem' }}>
                <span style={{ fontWeight: '600' }}>
                    <Link to="/policy">이용약관</Link>
                </span>
                <span className="bar">|</span>
                <span style={{ fontWeight: '600' }}>
                    <Link to="/cost">비용안내</Link>
                </span>
                <span className="bar">|</span>
                <span style={{ fontWeight: '600' }}>
                    <Link to="/support">고객센터</Link>
                </span>
            </div>
            <div className="footer">
                <span>대표이사 : 전영주</span>
                <span className="bar">|</span>
                <span>
                    경기도 하남시 미사강변서로22, 에코큐브지식산업센터 314호 <strong>주식회사 에즈비에프</strong>
                </span>
            </div>
            <div className="footer">
                <span>대표번호 : 0505-992-8282</span>
                <span className="bar">|</span>
                <span>FAX : 0508-954-0113</span>
                <span className="bar">|</span>
                <span>Email : asbf_coltd@naver.com</span>
            </div>
            <div className="footer">
                <span>사업자등록번호 : 887-86-03602</span>
                <span className="bar">|</span>
                <span>
                    <a
                        href="https://www.ftc.go.kr/www/bizCommView.do?key=3765&apv_perm_no=2020317023530202685&pageUnit=10&searchCnd=wrkr_no&searchKrwd=1408700556&pageIndex=1"
                        target="_blank"
                        rel="noreferrer"
                    >
                        통신판매업신고번호 : 2018-경기하남-0630
                    </a>
                </span>
            </div>
            <div className="sns">
                <a href="https://blog.naver.com/click_black" target="_blank" rel="noreferrer">
                    <img src={naver} alt="naver" />
                </a>
                <a href="https://pf.kakao.com/_FRxads/chat " target="_blank" rel="noreferrer">
                    <img src={kakao} alt="kakao" />
                </a>
            </div>
            <p>COPYRIGHT © AsBF. ALL RIGHTS RESERVED</p>
        </FooterContent>
    </FooterWrap>
);

export default FooterComponent;
